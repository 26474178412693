import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from "../ui/card";
import { Badge } from "../ui/badge";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { ScrollArea } from "../ui/scroll-area";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Button } from "../ui/button";

interface MencionesCardProps {
  menciones: {
    id: number;
    usuario: number;
    fecha: string;
    mensaje: string;
    leido: string;
    tipo: string;
  }[];
  handleMarkAsRead: (notificacionId: number, leido: boolean) => void;
  totalMenciones: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const MencionesCard: React.FC<MencionesCardProps> = ({
  menciones,
  handleMarkAsRead,
  totalMenciones,
  currentPage,
  onPageChange,
}) => {
  const pageSize = 10;
  const totalPages = Math.ceil(totalMenciones / pageSize);

  const extractSiniestroDetails = (mensaje: string) => {
    // Primero intentamos extraer usando el formato de menciones
    const match = mensaje.match(/siniestro #(\S+)\s*\(ID:\s*(\d+)\)/);

    if (match) {
      // Si encontramos el formato completo
      const companyIncidentNumber = match[1];
      const siniestroId = match[2];
      return { siniestroId, companyIncidentNumber, mainMessage: mensaje };
    }

    // Si no encontramos el formato completo, intentamos extraer solo el número
    const simpleMatch = mensaje.match(/siniestro #(\d+)/);
    if (simpleMatch) {
      const companyIncidentNumber = simpleMatch[1];
      // Usamos el mismo número como ID (solución temporal)
      return {
        siniestroId: companyIncidentNumber,
        companyIncidentNumber,
        mainMessage: mensaje,
      };
    }

    // Si no encontramos ningún formato
    return {
      siniestroId: null,
      companyIncidentNumber: null,
      mainMessage: mensaje,
    };
  };

  const handleMarkAsReadAndUpdate = (
    notificacionId: number,
    leido: boolean
  ) => {
    handleMarkAsRead(notificacionId, leido);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <PaginationItem key={i}>
            <PaginationLink
              href="#"
              isActive={currentPage === i}
              onClick={(e) => {
                e.preventDefault();
                onPageChange(i);
              }}
            >
              {i}
            </PaginationLink>
          </PaginationItem>
        );
      }
    } else {
      pageNumbers.push(
        <PaginationItem key={1}>
          <PaginationLink
            href="#"
            isActive={currentPage === 1}
            onClick={(e) => {
              e.preventDefault();
              onPageChange(1);
            }}
          >
            1
          </PaginationLink>
        </PaginationItem>
      );

      if (currentPage > 3) {
        pageNumbers.push(<PaginationEllipsis key="ellipsis1" />);
      }

      for (
        let i = Math.max(2, currentPage - 1);
        i <= Math.min(currentPage + 1, totalPages - 1);
        i++
      ) {
        pageNumbers.push(
          <PaginationItem key={i}>
            <PaginationLink
              href="#"
              isActive={currentPage === i}
              onClick={(e) => {
                e.preventDefault();
                onPageChange(i);
              }}
            >
              {i}
            </PaginationLink>
          </PaginationItem>
        );
      }

      if (currentPage < totalPages - 2) {
        pageNumbers.push(<PaginationEllipsis key="ellipsis2" />);
      }

      pageNumbers.push(
        <PaginationItem key={totalPages}>
          <PaginationLink
            href="#"
            isActive={currentPage === totalPages}
            onClick={(e) => {
              e.preventDefault();
              onPageChange(totalPages);
            }}
          >
            {totalPages}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return pageNumbers;
  };

  return (
    <Card className="h-full flex flex-col">
      <CardHeader>
        <CardTitle className="text-lg font-bold">Menciones</CardTitle>
      </CardHeader>
      <CardContent className="flex-1 flex flex-col">
        <ScrollArea className="h-[500px] p-2">
          <div className="flex flex-col gap-2">
            {menciones.map((notificacion) => {
              const { siniestroId, mainMessage, companyIncidentNumber } =
                extractSiniestroDetails(notificacion.mensaje);
              return (
                <ContextMenu key={notificacion.id}>
                  <ContextMenuTrigger className="flex flex-col w-full">
                    <Card
                      className={`border p-2 transition-all hover:bg-accent ${
                        notificacion.leido === "1"
                          ? "bg-muted dark:bg-gray-800"
                          : "bg-white dark:bg-gray-700"
                      }`}
                    >
                      <div className="flex justify-between items-start">
                        <div className="flex-1">
                          <CardTitle className="text-sm font-semibold">
                            <span className="font-bold text-lg dark:text-gray-200">
                              {companyIncidentNumber
                                ? `Siniestro #${companyIncidentNumber}`
                                : ""}
                            </span>
                            <br />
                            <span className="text-sm text-muted-foreground dark:text-gray-300">
                              {mainMessage}
                            </span>
                          </CardTitle>
                          <CardDescription className="text-xs text-muted-foreground dark:text-gray-400">
                            {format(parseISO(notificacion.fecha), "PPPPp", {
                              locale: es,
                            })}
                          </CardDescription>
                        </div>
                        <Badge
                          variant={
                            notificacion.leido === "0"
                              ? "destructive"
                              : "secondary"
                          }
                          className="ml-2 text-xs"
                        >
                          {notificacion.leido === "0" ? "No leído" : "Leído"}
                        </Badge>
                      </div>
                    </Card>
                  </ContextMenuTrigger>
                  <ContextMenuContent className="w-48">
                    <ContextMenuItem
                      onSelect={() =>
                        handleMarkAsReadAndUpdate(
                          notificacion.id,
                          notificacion.leido === "0"
                        )
                      }
                    >
                      Marcar como{" "}
                      {notificacion.leido === "0" ? "leído" : "no leído"}
                    </ContextMenuItem>
                    <ContextMenuSeparator />
                    <ContextMenuItem
                      onSelect={() => {
                        if (siniestroId) {
                          window.open(
                            `/siniestros/${siniestroId}/detalles`,
                            "_blank"
                          );
                        }
                      }}
                      disabled={!siniestroId}
                    >
                      Ir al siniestro
                    </ContextMenuItem>
                  </ContextMenuContent>
                </ContextMenu>
              );
            })}
          </div>
        </ScrollArea>
      </CardContent>
      <div className="mt-auto">
        <Pagination className="mb-4">
          <PaginationContent>
            <PaginationItem>
              <Button
                variant="outline"
                size="sm"
                onClick={() => onPageChange(1)}
                disabled={currentPage === 1}
              >
                Primera página
              </Button>
            </PaginationItem>
            <PaginationItem>
              <PaginationPrevious
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onPageChange(currentPage - 1);
                }}
                className={
                  currentPage === 1 ? "pointer-events-none opacity-50" : ""
                }
              />
            </PaginationItem>
            {renderPageNumbers()}
            <PaginationItem>
              <PaginationNext
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onPageChange(currentPage + 1);
                }}
                className={
                  currentPage === totalPages
                    ? "pointer-events-none opacity-50"
                    : ""
                }
              />
            </PaginationItem>
            <PaginationItem>
              <Button
                variant="outline"
                size="sm"
                onClick={() => onPageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                Última página
              </Button>
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
    </Card>
  );
};

export default MencionesCard;
