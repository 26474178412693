import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useEffect } from "react";

interface NumbersFilterProps {
  onChange: (value: string) => void;
  value: string | undefined;
  onSearch: () => void;
}
export function NumbersFilter({
  value,
  onChange,
  onSearch,
}: NumbersFilterProps) {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSearch();
    }
  };

  // Asegurarse de que siempre haya un valor por defecto
  const selectValue = value || "insuredName";

  // Notificar el valor inicial si no hay un valor definido
  useEffect(() => {
    if (!value) {
      onChange("insuredName");
    }
  }, [value, onChange]);

  return (
    <div onKeyDown={handleKeyDown}>
      <Select
        value={selectValue}
        onValueChange={(value) => {
          onChange(value);
        }}
      >
        <SelectTrigger className="w-[203px]">
          <SelectValue placeholder="Selecciona un filtro" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Filtros</SelectLabel>
            <SelectItem value="dni">DNI</SelectItem>
            <SelectItem value="insuredName">Nombre del asegurado</SelectItem>
            <SelectItem value="siniestroNumber">Nº Siniestro</SelectItem>
            <SelectItem value="receiptNumber">Nº orden de trabajo</SelectItem>
            <SelectItem value="deliveryNumber">Nº de entrega</SelectItem>
            <SelectItem value="policyNumber">Nº Póliza</SelectItem>
            <SelectItem value="electroReceiptNumber">
              Nº remito electro
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}
