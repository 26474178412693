import { apiFetch } from "./siniestroService";

export const fetchFacturas = async (
  pageIndex: number = 0,
  pageSize: number = 10,
  incidentNumber?: string,
  companyId?: number,
  facturaNumber?: number,
  status?: string,
  startDate?: Date,
  endDate?: Date,
  puntoVenta?: string
) => {
  let url = `/api/facturas?page=${pageIndex + 1}&itemsPerPage=${pageSize}`;

  const queryParams = [];
  if (incidentNumber) queryParams.push(`incidentNumber=${incidentNumber}`);
  if (companyId !== undefined) queryParams.push(`companyId=${companyId}`);
  if (facturaNumber !== undefined)
    queryParams.push(`facturaNumber=${facturaNumber}`);
  if (status) queryParams.push(`status=${status}`);
  if (startDate)
    queryParams.push(`startDate=${startDate.toISOString().split("T")[0]}`);
  if (endDate)
    queryParams.push(`endDate=${endDate.toISOString().split("T")[0]}`);
  if (puntoVenta) queryParams.push(`puntoVenta=${puntoVenta}`);
  if (queryParams.length > 0) {
    url += `&${queryParams.join("&")}`;
  }

  const response = await apiFetch(url);

  if (!response.ok) {
    throw new Error(`Error ${response.status}: Failed to fetch facturas`);
  }

  const data = await response.json();
  // Asegurarse de que cada factura tenga un punto_venta
  const facturasConPuntoVenta = data.facturas.map((factura: Factura) => ({
    ...factura,
    punto_venta: factura.punto_venta,
  }));

  return {
    ...data,
    facturas: facturasConPuntoVenta,
  };
};

export const fetchFacturaBySiniestroNumber = async (
  siniestroNumber: number
) => {
  try {
    const response = await apiFetch(
      `/api/facturas/siniestro/${siniestroNumber}`
    );

    if (!response.ok) {
      throw new Error(
        `Error ${response.status}: Failed to fetch factura by siniestro number`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const addFactura = async (facturaData: FacturaInput) => {
  try {
    const response = await apiFetch(`/api/facturas`, {
      method: "POST",
      body: JSON.stringify(facturaData),
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: Failed to add factura`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

function formatDate(date: Date): string {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export async function generarNotaCredito(
  facturaId: number,
  monto: number,
  motivo: string,
  compañiaAseguradora: string,
  rubro: string
) {
  const today = new Date();
  const nextMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate()
  );

  const requestBody = {
    compañiaAseguradora,
    facturaAsociada: {
      puntoVenta: "00006",
      numero: facturaId,
      fecha: formatDate(today),
    },
    detalleNotaCredito: [
      {
        cantidad: "1",
        producto: {
          descripcion: motivo,
          unidad_bulto: "1",
          lista_precios: "default",
          codigo: "NC001",
          precio_unitario_sin_iva: monto.toString(),
          alicuota: "21",
        },
      },
    ],
    total: monto,
    fecha_vencimiento: formatDate(nextMonth),
    rubro: rubro,
    rubro_grupo_contable: rubro,
  };

  try {
    const response = await apiFetch("/api/facturas/generar-nota-credito", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Error response:", response.status, errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error en generarNotaCredito:", error);
    throw error;
  }
}

export const deleteFactura = async (id: number) => {
  try {
    const response = await apiFetch(`/api/facturas/${id}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: Failed to delete factura`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const changeStatusFactura = async (id: number, status: string) => {
  try {
    const response = await apiFetch(`/api/facturas/${id}`, {
      method: "PATCH",
      body: JSON.stringify({ status }),
    });

    if (!response.ok) {
      throw new Error(
        `Error ${response.status}: Failed to change status of factura`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateStatusMultipleFacturas = async (
  facturaIds: number[],
  status: string
) => {
  try {
    const response = await apiFetch(`/api/facturas/facturas-status`, {
      method: "PATCH",
      body: JSON.stringify({ facturaIds, status }),
    });

    if (!response.ok) {
      throw new Error(
        `Error ${response.status}: Failed to change status of multiple facturas`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateStatusByFacturaNumbers = async (
  facturaNumbers: number[],
  newStatus: string
) => {
  try {
    const response = await apiFetch("/api/facturas/update-status-by-numbers", {
      method: "POST",
      body: JSON.stringify({ facturaNumbers, newStatus }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error en updateStatusByFacturaNumbers:", error);
    throw error;
  }
};

export const generarFacturaC = async (
  siniestroId: number,
  detalleFactura: any[],
  compañiaAseguradora: string,
  rubro: string,
  companyIncidentNumber: string
) => {
  try {
    const response = await apiFetch(`/api/facturas/generar-factura`, {
      method: "POST",
      body: JSON.stringify({
        siniestroId,
        detalleFactura,
        compañiaAseguradora,
        rubro,
        companyIncidentNumber,
      }),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Error en la respuesta del servidor");
    }
    return data;
  } catch (error) {
    console.error("Error al generar la factura C:", error);
    throw error;
  }
};
