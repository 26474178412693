import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { toast } from "sonner";
import {
  getSiniestrosByBrand,
  getCompanies,
  getSegments,
  getSiniestrosByZipcode,
} from "@/services/siniestroService";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
  LabelList,
} from "recharts";
import {
  Select,
  SelectValue,
  SelectTrigger,
  SelectItem,
  SelectContent,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { HiChartPie, HiChartBar, HiLocationMarker } from "react-icons/hi";
import { FaFilter } from "react-icons/fa";
import DateFilter from "./DateFilter";
import { useAuth } from "@/contexts/AuthContext";

interface Company {
  id: number;
  name: string;
}

interface ItemType {
  id: number;
  name: string;
}

interface Filters {
  companyId: string | null;
  itemTypeName: string | null;
  startDate: Date | null;
  endDate: Date | null;
}

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884d8",
  "#82ca9d",
];

const getTotalCauses = (stats: any[]) => {
  const totals = stats.reduce(
    (acc, curr) => {
      return {
        caidas: acc.caidas + curr.causes.caidas,
        rayos: acc.rayos + curr.causes.rayos,
        tension: acc.tension + curr.causes.tension,
        otros: acc.otros + curr.causes.otros,
      };
    },
    { caidas: 0, rayos: 0, tension: 0, otros: 0 }
  );

  return [
    { name: "Caída", value: totals.caidas },
    { name: "Rayo", value: totals.rayos },
    { name: "Tensión", value: totals.tension },
    { name: "Otros", value: totals.otros },
  ]
    .filter((item) => item.value > 0)
    .sort((a, b) => b.value - a.value); // Ordenar de mayor a menor
};

const getTotalPaymentTypes = (stats: any[]) => {
  const totals = stats.reduce(
    (acc, curr) => ({
      cheque: acc.cheque + (curr.paymentTypes?.cheque || 0),
      ordenCompra: acc.ordenCompra + (curr.paymentTypes?.ordenCompra || 0),
      transferencia:
        acc.transferencia + (curr.paymentTypes?.transferencia || 0),
      garbarino: acc.garbarino + (curr.paymentTypes?.garbarino || 0),
      falabella: acc.falabella + (curr.paymentTypes?.falabella || 0),
      fravega: acc.fravega + (curr.paymentTypes?.fravega || 0),
      authogar: acc.authogar + (curr.paymentTypes?.authogar || 0),
      musimundo: acc.musimundo + (curr.paymentTypes?.musimundo || 0),
      mercadoPago: acc.mercadoPago + (curr.paymentTypes?.mercadoPago || 0),
      reposicionAllianz:
        acc.reposicionAllianz + (curr.paymentTypes?.reposicionAllianz || 0),
      reposicionTuService:
        acc.reposicionTuService + (curr.paymentTypes?.reposicionTuService || 0),
      otros: acc.otros + (curr.paymentTypes?.otros || 0),
    }),
    {
      cheque: 0,
      ordenCompra: 0,
      transferencia: 0,
      garbarino: 0,
      falabella: 0,
      fravega: 0,
      authogar: 0,
      musimundo: 0,
      mercadoPago: 0,
      reposicionAllianz: 0,
      reposicionTuService: 0,
      otros: 0,
    }
  );

  const result = [
    { name: "Cheque", value: totals.cheque },
    { name: "Orden de compra", value: totals.ordenCompra },
    { name: "Transferencia", value: totals.transferencia },
    { name: "Garbarino", value: totals.garbarino },
    { name: "Falabella", value: totals.falabella },
    { name: "Frávega", value: totals.fravega },
    { name: "Authogar", value: totals.authogar },
    { name: "Musimundo", value: totals.musimundo },
    { name: "MercadoPago", value: totals.mercadoPago },
    { name: "Rep. Allianz", value: totals.reposicionAllianz },
    { name: "Rep. Tu Service", value: totals.reposicionTuService },
    { name: "Otros", value: totals.otros },
  ]
    .filter((item) => item.value > 0)
    .sort((a, b) => b.value - a.value); // Ordenar de mayor a menor

  return result;
};

const getTotalResolutions = (stats: any[]) => {
  const totals = stats.reduce(
    (acc, curr) => ({
      rechazado: acc.rechazado + (curr.resolutions?.rechazados || 0),
      reparado: acc.reparado + (curr.resolutions?.reparados || 0),
      reingresoGarantia:
        acc.reingresoGarantia + (curr.resolutions?.reingresos || 0),
      desistido: acc.desistido + (curr.resolutions?.desistidos || 0),
      reposicionAllianz:
        acc.reposicionAllianz +
        (Array.isArray(curr.resolutions?.reposicionAllianz)
          ? curr.resolutions.reposicionAllianz.reduce(
              (a: number, b: number) => a + b,
              0
            )
          : curr.resolutions?.reposicionAllianz || 0),
      reposicionAsegurado:
        acc.reposicionAsegurado + (curr.resolutions?.reposicionAsegurado || 0),
      liquidado: acc.liquidado + (curr.resolutions?.liquidados || 0),
    }),
    {
      rechazado: 0,
      reparado: 0,
      reingresoGarantia: 0,
      desistido: 0,
      reposicionAllianz: 0,
      reposicionAsegurado: 0,
      liquidado: 0,
    }
  );

  return [
    { name: "Rechazado", value: totals.rechazado },
    { name: "Reparado", value: totals.reparado },
    { name: "Reingreso Garantía", value: totals.reingresoGarantia },
    { name: "Desistido", value: totals.desistido },
    { name: "Rep. Allianz", value: totals.reposicionAllianz },
    { name: "Rep. Asegurado", value: totals.reposicionAsegurado },
    { name: "Liquidado", value: totals.liquidado },
  ]
    .filter((item) => item.value > 0)
    .sort((a, b) => b.value - a.value); // Ordenar de mayor a menor
};

const SiniestrosStatsPage = () => {
  const [filters, setFilters] = useState<Filters>({
    companyId: null,
    itemTypeName: null,
    startDate: null,
    endDate: null,
  });
  const [companies, setCompanies] = useState<Company[]>([]);
  const [itemTypes, setItemTypes] = useState<ItemType[]>([]);
  const [brandStats, setBrandStats] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth() + 1
  );
  const [zipcodeStats, setZipcodeStats] = useState<
    Array<{ zipcode: string; total: number }>
  >([]);
  const { roles } = useAuth();
  const hasRole7 = roles.includes(7);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        const [companiesData, segmentsData] = await Promise.all([
          getCompanies(),
          getSegments(),
        ]);
        setCompanies(companiesData);
        setItemTypes(segmentsData);

        const startDate = `${selectedYear}-${String(selectedMonth).padStart(
          2,
          "0"
        )}-01`;
        const lastDay = new Date(selectedYear, selectedMonth, 0).getDate();
        const endDate = `${selectedYear}-${String(selectedMonth).padStart(
          2,
          "0"
        )}-${String(lastDay).padStart(2, "0")}`;

        const [brandDataResponse, zipcodeDataResponse] = await Promise.all([
          getSiniestrosByBrand({
            companyId: filters.companyId
              ? Number(filters.companyId)
              : undefined,
            itemTypeName: filters.itemTypeName || undefined,
            startDate,
            endDate,
          }),
          getSiniestrosByZipcode({
            companyId: filters.companyId
              ? Number(filters.companyId)
              : undefined,
            itemTypeName: filters.itemTypeName || undefined,
            startDate,
            endDate,
          }),
        ]);

        const brandData = await brandDataResponse.json();
        setBrandStats(brandData.stats || []);
        setZipcodeStats(zipcodeDataResponse || []);
      } catch (error) {
        console.error("Error loading data:", error);
        toast.error("Error al cargar los datos");
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  const handleFilterChange = (field: string, value: any) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  const handleApplyFilters = async () => {
    if (!filters.itemTypeName) {
      toast.error("Por favor, seleccione todos los campos requeridos");
      return;
    }

    try {
      setIsLoading(true);
      const startDate = `${selectedYear}-${String(selectedMonth).padStart(
        2,
        "0"
      )}-01`;
      const lastDay = new Date(selectedYear, selectedMonth, 0).getDate();
      const endDate = `${selectedYear}-${String(selectedMonth).padStart(
        2,
        "0"
      )}-${String(lastDay).padStart(2, "0")}`;

      const [brandData, zipcodeData] = await Promise.all([
        getSiniestrosByBrand({
          companyId: Number(filters.companyId),
          itemTypeName: filters.itemTypeName,
          startDate,
          endDate,
        }),
        getSiniestrosByZipcode({
          companyId: Number(filters.companyId),
          itemTypeName: filters.itemTypeName,
          startDate,
          endDate,
        }),
      ]);

      setBrandStats(brandData || []);
      setZipcodeStats(zipcodeData || []);
    } catch (error) {
      console.error("Error applying filters:", error);
      toast.error("Error al aplicar los filtros");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">Estadísticas de Siniestros</h1>
        <div className="grid gap-4">
          <Skeleton className="h-[200px]" />
          <Skeleton className="h-[400px]" />
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 space-y-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Estadísticas de Siniestros</h1>
      </div>

      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <FaFilter className="h-5 w-5" />
            Filtros
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            {!hasRole7 && (
              <Select
                onValueChange={(value) =>
                  handleFilterChange("companyId", value)
                }
                value={filters.companyId || undefined}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Seleccionar Compañía" />
                </SelectTrigger>
                <SelectContent className="overflow-y-auto max-h-[30rem]">
                  {companies.map((company) => (
                    <SelectItem key={company.id} value={company.id.toString()}>
                      {company.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}

            <Select
              onValueChange={(value) =>
                handleFilterChange("itemTypeName", value)
              }
              value={filters.itemTypeName || undefined}
              required
            >
              <SelectTrigger>
                <SelectValue placeholder="Segmento" />
              </SelectTrigger>
              <SelectContent>
                {itemTypes.map((type) => (
                  <SelectItem key={type.id} value={type.name}>
                    {type.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <DateFilter
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              onYearChange={(year) => setSelectedYear(Number(year))}
              onMonthChange={(month) => setSelectedMonth(Number(month))}
            />

            <Button
              onClick={handleApplyFilters}
              className="w-full"
              disabled={!filters.itemTypeName}
            >
              Aplicar filtros
            </Button>
          </div>
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Gráfico de Barras por Marca */}
        <Card className="col-span-1 md:col-span-3">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <HiChartBar className="h-6 w-6 text-blue-500" />
              Total por Marca
            </CardTitle>
          </CardHeader>
          <CardContent className="h-[400px]">
            {isLoading ? (
              <Skeleton className="w-full h-full" />
            ) : brandStats.length > 0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={brandStats}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="brandName"
                    angle={-45}
                    textAnchor="end"
                    height={100}
                  />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="total" fill="#8884d8" name="Total" />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <p className="text-center text-muted-foreground">
                No hay datos disponibles
              </p>
            )}
          </CardContent>
        </Card>

        {/* Card combinada para Causas y Resoluciones */}
        <Card className="col-span-1 md:col-span-3">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <HiChartPie className="h-6 w-6 text-purple-500" />
              Distribución por causa, resolución y tipos de pago
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              {/* Gráfico de Causas */}
              <div className="h-[500px] pt-4 md:pt-0">
                <h3 className="text-center font-semibold mb-4">Causas</h3>
                <div className="h-[450px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={getTotalCauses(brandStats)}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="35%" // Ajustado de 40% a 35% para dar más espacio a la leyenda
                        innerRadius={50}
                        outerRadius={90} // Reducido de 100 a 90
                      >
                        {getTotalCauses(brandStats).map((_, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend
                        layout="vertical"
                        align="center"
                        verticalAlign="bottom"
                        wrapperStyle={{
                          paddingTop: "20px",
                          maxHeight: "200px",
                        }}
                        formatter={(_, entry: any) => {
                          const percentage = (
                            (entry.payload.value /
                              getTotalCauses(brandStats).reduce(
                                (acc, curr) => acc + curr.value,
                                0
                              )) *
                            100
                          ).toFixed(1);
                          return `${entry.payload.name} (${percentage}%)`;
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
              {/* Gráfico de Resoluciones - aplicar los mismos cambios */}
              <div className="h-[500px] pt-4 md:pt-0">
                <h3 className="text-center font-semibold mb-4">Resoluciones</h3>
                <div className="h-[450px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={getTotalResolutions(brandStats)}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="35%"
                        innerRadius={50}
                        outerRadius={90}
                      >
                        {getTotalResolutions(brandStats).map((_, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend
                        layout="vertical"
                        align="center"
                        verticalAlign="bottom"
                        wrapperStyle={{
                          paddingTop: "20px",
                          maxHeight: "200px",
                        }}
                        formatter={(_, entry: any) => {
                          const percentage = (
                            (entry.payload.value /
                              getTotalResolutions(brandStats).reduce(
                                (acc, curr) => acc + curr.value,
                                0
                              )) *
                            100
                          ).toFixed(1);
                          return `${entry.payload.name} (${percentage}%)`;
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
              {/* Gráfico de Tipos de Pago - aplicar los mismos cambios */}
              <div className="h-[500px] pt-4 md:pt-0">
                <h3 className="text-center font-semibold mb-4">
                  Tipos de Pago
                </h3>
                <div className="h-[450px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={getTotalPaymentTypes(brandStats)}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="35%"
                        innerRadius={50}
                        outerRadius={90}
                      >
                        {getTotalPaymentTypes(brandStats).map((_, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend
                        layout="vertical"
                        align="center"
                        verticalAlign="bottom"
                        wrapperStyle={{
                          paddingTop: "20px",
                          maxHeight: "200px",
                        }}
                        formatter={(_, entry: any) => {
                          const percentage = (
                            (entry.payload.value /
                              getTotalPaymentTypes(brandStats).reduce(
                                (acc, curr) => acc + curr.value,
                                0
                              )) *
                            100
                          ).toFixed(1);
                          return `${entry.payload.name} (${percentage}%)`;
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <Card className="col-span-1 md:col-span-3 mt-6">
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <HiLocationMarker className="h-6 w-6 text-blue-500" />
            Distribución por Código Postal (Top 10)
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[400px]">
            {isLoading ? (
              <Skeleton className="w-full h-full" />
            ) : zipcodeStats.length > 0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={zipcodeStats.slice(0, 10)}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="zipcode"
                    label={{ value: "Código Postal", position: "bottom" }}
                  />
                  <YAxis
                    label={{
                      value: "Cantidad",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Bar dataKey="total" fill="#8884d8" name="Cantidad">
                    <LabelList dataKey="total" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <p className="text-center text-muted-foreground">
                No hay datos disponibles
              </p>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SiniestrosStatsPage;
