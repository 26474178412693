import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { getUserInfo, fetchTramitadores } from "@/services/siniestroService";
import { toast } from "sonner";
import { ReloadIcon } from "@radix-ui/react-icons";
import { AgendaItem } from "./SiniestroDetails/AgendaInterna";
import { apiFetch } from "@/services/siniestroService";

interface TextAreaAgendaProps {
  siniestroId: number;
  onAgendaUpdated: (updatedAgenda: AgendaItem[]) => void;
  companyIncidentNumber: string;
}

export function TextAreaAgenda({
  siniestroId,
  onAgendaUpdated,
  companyIncidentNumber,
}: TextAreaAgendaProps) {
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tramitadores, setTramitadores] = useState<Tramitador[]>([]);
  const [filteredTramitadores, setFilteredTramitadores] = useState<
    Tramitador[]
  >([]);
  const [showTramitadorList, setShowTramitadorList] = useState(false);
  const [selectedMentions, setSelectedMentions] = useState<string[]>([]);

  useEffect(() => {
    const fetchUserInfoAndTramitadores = async () => {
      try {
        const userInfo = await getUserInfo();
        setUserId(userInfo.id);

        const tramitadoresList = await fetchTramitadores();
        setTramitadores(tramitadoresList);
      } catch (error) {
        console.error(
          "Error al obtener información del usuario o lista de tramitadores:",
          error
        );
      }
    };

    void fetchUserInfoAndTramitadores();
  }, [siniestroId]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setMessage(value);

    const mentionIndex = value.lastIndexOf("@");
    if (mentionIndex !== -1) {
      const query = value.slice(mentionIndex + 1).toLowerCase();
      const filtered = tramitadores.filter((tramitador) =>
        tramitador.display_name.toLowerCase().includes(query)
      );
      setFilteredTramitadores(filtered);
      setShowTramitadorList(true);
    } else {
      setShowTramitadorList(false);
    }
  };

  const handleTramitadorSelect = (
    displayName: string,
    tramitador: Tramitador
  ) => {
    const mentionIndex = message.lastIndexOf("@");
    const newMessage =
      message.slice(0, mentionIndex) + `@${displayName.trim()} `;
    setMessage(newMessage);
    setShowTramitadorList(false);

    // Agregar el username a las menciones seleccionadas
    if (tramitador.display_name) {
      setSelectedMentions((prev) => [...prev, tramitador.display_name]);
    }
  };

  const handleSendMessage = async () => {
    if (message.trim().length === 0 || !userId || isLoading) return;

    try {
      setIsLoading(true);

      // Usar las menciones seleccionadas directamente
      if (selectedMentions.length > 0) {
        const mentionResponse = await apiFetch(`/api/notificaciones/mention`, {
          method: "POST",
          body: JSON.stringify({
            userId,
            mensaje: message,
            tipo: "comentario",
            siniestroId,
            companyIncidentNumber,
            mentions: selectedMentions,
          }),
        });

        if (!mentionResponse.ok) {
          const errorText = await mentionResponse.text();
          console.error(
            "Error al enviar notificaciones de mención:",
            errorText
          );
        }
      }

      // Enviar el mensaje a la agenda interna
      const response = await apiFetch(`/api/siniestros/${siniestroId}/agenda`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId,
          text: message,
          siniestroId,
          companyIncidentNumber,
        }),
      });

      if (!response.ok) {
        throw new Error("Error al agregar el mensaje a la agenda interna");
      }

      toast.success("Mensaje agregado a la agenda interna");
      setMessage("");
      // Limpiar las menciones seleccionadas después de enviar el mensaje
      setSelectedMentions([]);

      // Actualizar la agenda
      const updatedResponse = await apiFetch(
        `/api/siniestros/${siniestroId}/agenda`
      );
      const data = await updatedResponse.json();
      onAgendaUpdated(data.agenda);
    } catch (error) {
      console.error("Error completo:", error);
      toast.error("Error al enviar el mensaje a la agenda interna");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="grid w-full gap-2">
      <Textarea
        placeholder="Escribe un mensaje para la agenda interna"
        value={message}
        onChange={handleInputChange}
        className="min-h-[100px]"
        rows={4}
      />

      {showTramitadorList && (
        <ul className="bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded shadow-md max-h-40 overflow-y-auto z-10 relative">
          {filteredTramitadores.map((tramitador) => (
            <li
              key={tramitador.id}
              className="p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-900 dark:text-gray-100 transition-colors"
              onClick={() =>
                handleTramitadorSelect(tramitador.display_name, tramitador)
              }
            >
              {tramitador.display_name}
            </li>
          ))}
        </ul>
      )}

      {/* Mostrar las menciones activas */}
      {selectedMentions.length > 0 && (
        <div className="text-sm text-blue-500 mt-1">
          Menciones: {selectedMentions.join(", ")}
        </div>
      )}

      {isLoading ? (
        <Button disabled>
          <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
          Enviando...
        </Button>
      ) : (
        <Button onClick={handleSendMessage}>Agregar a la agenda</Button>
      )}
    </div>
  );
}
