import DniSearch from "@/components/siniestros/DniSearch";
import DireccionSearch from "@/components/siniestros/DireccionSearch";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

export default function SearchPage() {
  return (
    <Tabs defaultValue="dni">
      <TabsList className="mx-auto max-w-md flex justify-center gap-4">
        <TabsTrigger value="dni">Búsqueda por DNI</TabsTrigger>
        <TabsTrigger value="direccion">Búsqueda por Dirección</TabsTrigger>
      </TabsList>
      <TabsContent value="dni">
        <DniSearch />
      </TabsContent>
      <TabsContent value="direccion">
        <DireccionSearch />
      </TabsContent>
    </Tabs>
  );
}
