/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable @typescript-eslint/no-misused-promises */

import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { ReloadIcon } from "@radix-ui/react-icons";
import { CarbonDeliveryAdd } from "@/components/icons/CarbonDeliveryAdd";
import { addAccionToSiniestro } from "@/services/siniestroService";
import { apiFetch } from "@/services/siniestroService";
import { toast } from "sonner";
import { useAuth } from "@/contexts/AuthContext";

interface BienSelectionDialogProps {
  bienes: Bien[];
  siniestro: Siniestro;
  onGeneratePdf: (bienes: Bien[], type: string) => Promise<void>;
}

export const BienSelectionRetiroDialog = ({
  bienes,
  siniestro,
  onGeneratePdf,
}: BienSelectionDialogProps) => {
  const [selectedBienes, setSelectedBienes] = useState<number[]>([]);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { roles } = useAuth();
  const hasRole11 = roles.includes(11);

  const toggleBien = (bienId: number) => {
    setSelectedBienes((prevSelectedBienes) =>
      prevSelectedBienes.includes(bienId)
        ? prevSelectedBienes.filter((id) => id !== bienId)
        : [...prevSelectedBienes, bienId]
    );
  };

  const handleConfirmChangeStatus = async () => {
    try {
      const response = await apiFetch(`/api/siniestros/${siniestro.id}`, {
        method: "PATCH",
        body: JSON.stringify({ estadoId: 20 }),
      });

      if (!response.ok) {
        throw new Error("Error al actualizar el estado del siniestro");
      }

      await addAccionToSiniestro(siniestro.id, 561, null, null);
      toast.success("Estado del siniestro actualizado a Visita coordinada");
    } catch (error) {
      toast.error("Error al actualizar el estado del siniestro");
    } finally {
      setShowConfirmationDialog(false);
    }
  };

  const handleGeneratePdf = async () => {
    setIsGeneratingPdf(true);
    const bienesToExport = bienes.filter((bien) =>
      selectedBienes.includes(bien.id)
    );
    try {
      await onGeneratePdf(bienesToExport, siniestro.type);
      await addAccionToSiniestro(siniestro.id, 600, null, null);
      if (hasRole11) {
        setShowConfirmationDialog(true);
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Error al generar el PDF");
    } finally {
      setIsGeneratingPdf(false);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="retiro">
          <CarbonDeliveryAdd className="mr-2 h-4 w-4" />
          Imprimir PDF remito de retiro
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Seleccione los bienes</DialogTitle>
          <DialogDescription>
            Seleccione los bienes que desea incluir en el remito de retiro.
          </DialogDescription>
        </DialogHeader>
        {bienes.map((bien) => (
          <div key={bien.id} className="flex items-center justify-start">
            <Checkbox
              id={`bien-${bien.id}`}
              checked={selectedBienes.includes(bien.id)}
              onCheckedChange={() => {
                toggleBien(bien.id);
              }}
            />
            <label htmlFor={`bien-${bien.id}`} className="ml-2">
              {bien.itemType} - {bien.brandName} - {bien.model} - {bien.serial}
            </label>
          </div>
        ))}
        <DialogFooter>
          {selectedBienes.length === 0 && (
            <p className="text-sm text-red-500 mb-2">
              Por favor, seleccione al menos un bien.
            </p>
          )}
          <Button
            onClick={handleGeneratePdf}
            disabled={isGeneratingPdf || selectedBienes.length === 0}
          >
            {isGeneratingPdf ? (
              <>
                <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                Generando PDF...
              </>
            ) : (
              "Generar PDF"
            )}
          </Button>
        </DialogFooter>
        {showConfirmationDialog && (
          <Dialog
            open={showConfirmationDialog}
            onOpenChange={setShowConfirmationDialog}
          >
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Cambiar estado del siniestro</DialogTitle>
                <DialogDescription>
                  ¿Desea cambiar el estado del siniestro a "Visita coordinada"?
                </DialogDescription>
              </DialogHeader>
              <DialogFooter className="flex justify-between gap-2">
                <Button
                  variant="outline"
                  onClick={() => setShowConfirmationDialog(false)}
                >
                  No
                </Button>
                <Button onClick={handleConfirmChangeStatus}>
                  Sí, cambiar estado
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        )}
      </DialogContent>
    </Dialog>
  );
};
