import React, { useState } from "react";
import { Search } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Loader2 } from "lucide-react";
import { format } from "@formkit/tempo";
import { apiFetch } from "@/services/siniestroService";

const DniSearch: React.FC = () => {
  const [dni, setDni] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [siniestros, setSiniestros] = useState<Siniestro[]>([]);

  const handleSearch = async () => {
    if (!dni.trim()) {
      setError("Por favor ingrese un DNI");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await apiFetch(`/api/siniestros/search-by-dni/${dni}`);
      if (!response.ok) {
        throw new Error("Error al buscar siniestros");
      }

      const data = await response.json();
      setSiniestros(data.siniestros);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "Error al buscar siniestros"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="mx-4 my-6">
      <CardHeader>
        <CardTitle>Búsqueda por DNI</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex gap-4 mb-6">
          <Input
            placeholder="Ingrese DNI"
            value={dni}
            onChange={(e) => setDni(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSearch()}
            className="max-w-[200px]"
          />
          <Button onClick={handleSearch} disabled={loading}>
            {loading ? (
              <Loader2 className="h-4 w-4 animate-spin mr-2" />
            ) : (
              <Search className="h-4 w-4 mr-2" />
            )}
            Buscar
          </Button>
        </div>

        {error && (
          <Alert variant="destructive" className="mb-6">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Asegurado</TableHead>
                <TableHead>Dirección</TableHead>
                <TableHead>Cantidad de bienes</TableHead>
                <TableHead>Compañía</TableHead>
                <TableHead>Fecha</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={5} className="text-center">
                    <Loader2 className="h-6 w-6 animate-spin mx-auto" />
                  </TableCell>
                </TableRow>
              ) : siniestros.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    className="text-center text-muted-foreground"
                  >
                    No se encontraron resultados
                  </TableCell>
                </TableRow>
              ) : (
                siniestros.map((siniestro) => (
                  <TableRow key={siniestro.id}>
                    <TableCell>{siniestro.insuredName}</TableCell>
                    <TableCell>{siniestro.fullAddress}</TableCell>
                    <TableCell>{siniestro.itemCount}</TableCell>
                    <TableCell>{siniestro.companyName}</TableCell>
                    <TableCell>
                      {format(new Date(siniestro.insertedAt), "medium")}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default DniSearch;
