/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from "react";
import { format, parseISO } from "date-fns";
import Spinner from "../../ui/spinner";
import { Card, CardContent } from "../../ui/card";
import { TextAreaAgenda } from "../TextAreaAgenda";
import {
  FaClock,
  FaCommentAlt,
  FaClipboardList,
  FaUserCircle,
  FaInfoCircle,
} from "react-icons/fa";
import "./CustomScrollbar.css";
import { Alert, AlertDescription } from "@/components/ui/alert";

export interface AgendaItem {
  id: number;
  incident_id: number;
  user_id: number;
  text: string;
  insertedAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  userName: string;
}

interface AgendaInternaProps {
  agenda: AgendaItem[];
  isLoadingAgenda: boolean;
  siniestroId: number;
  onAgendaUpdated: (updatedAgenda: React.SetStateAction<AgendaItem[]>) => void;
  companyIncidentNumber: string;
}

const convertUrlsToLinks = (text: string | null | undefined) => {
  if (!text) return "";
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(
    urlRegex,
    (url) =>
      `<a href="${url}" target="_blank" rel="noopener noreferrer" style="text-decoration: underline; color: blue;">${url}</a>`
  );
};

const formatDate = (dateString: string | null | undefined): string => {
  if (!dateString || isNaN(Date.parse(dateString))) {
    return "Fecha no disponible";
  }
  return format(parseISO(dateString), "dd/MM/yyyy HH:mm");
};

export const AgendaInterna: React.FC<AgendaInternaProps> = ({
  agenda,
  isLoadingAgenda,
  siniestroId,
  onAgendaUpdated,
  companyIncidentNumber,
}) => {
  return (
    <CardContent className="p-6">
      <Card className="bg-gradient-to-br from-green-50 to-green-100 dark:from-gray-800 dark:to-gray-700 p-6 rounded-lg shadow-lg">
        <Alert className="mb-4 bg-blue-50 dark:bg-blue-900/30 border-blue-200 dark:border-blue-800">
          <FaInfoCircle className="h-4 w-4 text-blue-600 dark:text-blue-400" />
          <AlertDescription className="text-blue-700 dark:text-blue-300">
            Puedes mencionar a otros usuarios usando @ seguido de su nombre
            (ejemplo: @Usuario)
          </AlertDescription>
        </Alert>
        <h3 className="font-bold text-xl text-green-700 dark:text-green-300 mb-6 flex items-center">
          <FaClipboardList className="mr-3" /> Agenda interna
        </h3>
        <div className="space-y-4 max-h-[32rem] overflow-y-auto pr-2 custom-scrollbar">
          {isLoadingAgenda ? (
            <Spinner />
          ) : (
            agenda.map((entry, index) => (
              <div
                key={entry.id || index}
                className={`bg-white dark:bg-gray-900 p-4 rounded-2xl ${
                  index === agenda.length - 1
                    ? "border-2 border-green-500 dark:border-green-400"
                    : ""
                }`}
              >
                <div className="flex items-center justify-between text-sm text-gray-600 dark:text-gray-300 mb-2">
                  <div className="flex items-center">
                    <FaUserCircle className="mr-2 text-green-500 dark:text-green-400" />
                    <span className="font-semibold">{entry.userName}</span>
                  </div>
                  <div className="flex items-center text-gray-500 dark:text-gray-400">
                    <FaClock className="mr-2" />
                    <span>{formatDate(entry.insertedAt)}</span>
                  </div>
                </div>
                <div
                  className="text-gray-700 dark:text-gray-200 mt-2 pl-6 border-l-2 border-green-200 dark:border-green-600 break-words overflow-wrap-anywhere"
                  dangerouslySetInnerHTML={{
                    __html: convertUrlsToLinks(entry.text),
                  }}
                />
              </div>
            ))
          )}
        </div>
      </Card>
      <Card className="bg-gradient-to-br from-yellow-50 to-yellow-100 dark:from-gray-800 dark:to-gray-700 p-4 rounded-lg shadow-lg mt-6">
        <h3 className="font-bold text-xl text-yellow-700 dark:text-yellow-300 mb-4 flex items-center">
          <FaCommentAlt className="mr-3" /> Agregar comentario
        </h3>
        <TextAreaAgenda
          siniestroId={siniestroId}
          onAgendaUpdated={onAgendaUpdated}
          companyIncidentNumber={companyIncidentNumber}
        />
      </Card>
    </CardContent>
  );
};

export default AgendaInterna;
