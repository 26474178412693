import { useState, useEffect } from "react";
import { apiFetch } from "@/services/siniestroService";

interface AgendaItem {
  id: number;
  incident_id: number;
  user_id: number;
  text: string;
  insertedAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  userName: string;
}

export const useAgendaInterna = (siniestroId: number) => {
  const [agenda, setAgenda] = useState<AgendaItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAgenda = async () => {
      try {
        const response = await apiFetch(
          `/api/siniestros/${siniestroId}/agenda`
        );
        const data = await response.json();
        setAgenda(data.agenda);
      } catch (err) {
        setError("Error al cargar la agenda interna");
      } finally {
        setIsLoading(false);
      }
    };

    fetchAgenda();
  }, [siniestroId]);

  return { agenda, isLoading, error, setAgenda }; // Exponemos setAgenda
};
